import React, {Fragment, useEffect} from 'react'
import {observer} from 'mobx-react'
import {useStore} from '../hooks/useStore.js'

import {
	LoadingScreen
} from './'

import {
	QuestionSheet,
	ReportCard,
	SubjectsSheet,
	BonusQuestionSheet
} from '../components'

import {
	Nav,
	DayAndSubjectLabel
} from '../components/global'

import {
	PhoneIDLabel
} from '../components/subcomponents'

import {
	scrollToTop
} from '../helpers'

const UserView = observer((props) => {
	const {isReady, currentSubject,showBonusQuestion, firebaseStore: {userDayAnswers}} = useStore();

	useEffect(() => {
		scrollToTop()
	});

	if (!isReady) return <LoadingScreen/>


	let component;	
	if (currentSubject === "--") {
		component = <ReportCard/>
	} else if (showBonusQuestion) {
		component = <Fragment><DayAndSubjectLabel/><BonusQuestionSheet/></Fragment>
	}else if (userDayAnswers[currentSubject]) {
		component = <Fragment><DayAndSubjectLabel/><SubjectsSheet/></Fragment>
	} else {
		component = <Fragment><DayAndSubjectLabel/><QuestionSheet/></Fragment>
	}


	return (
		<div className={`user-view animate-in ${showBonusQuestion ? "bonus-active" : ""}`}>
			<Nav/>
			
			{component}
			
			<PhoneIDLabel/>
		</div>

	)
})

export default UserView