import React, {Component, Fragment} from 'react'
import {inject, observer} from 'mobx-react'

import {
	CountryCodeInput
} from '../components/subcomponents'

import {
	TouchButtonHOC
} from '../components/global'

import {
	reportErrorCode 
} from '../helpers'

@inject("store") @observer
class Login extends Component {
	state = {
		smsSent: false,
		error: false
	}
	
	componentDidMount() {
		this.props.store.firebaseStore.setupCaptcha()
	}


	signInWithPhoneNumber() {
		const countryCode = this.props.store.uiStore.selectedCountryCode;
		const phoneInputVal = this.refs['phone-input'].value;

		if (!phoneInputVal) return

		const phoneNumber = `${countryCode}${phoneInputVal.replace(/[^0-9]/g, '')}`;

		var appVerifier = window.recaptchaVerifier;
		let that = this;
		const firebase = this.props.store.firebaseStore.firebase

		firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
		  .then(function() {
		    return firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
		        .then(function (confirmationResult) {

		          window.confirmationResult = confirmationResult;
		          that.setState({
		          	smsSent: true
		          });
		        }).catch(function (error) {
		        	console.log(error);
		        	that.setState({
		        		error
		        	});

		        });		  
		    })
		  .catch(function(error) {
				// Handle Errors here.

		    	that.setState({
		    		error
		    	});

			    var errorCode = error.code;
			    var errorMessage = error.message;

			    console.error(errorMessage)
		  });

	}

	verify() {
		const code = this.refs['verification-code'].value;
		if (!code) return

	   	let that = this;

	   	window.confirmationResult.confirm(code).then(function (result) {
		  // User signed in successfully.
		  var user = result.user;
		  that.props.store.firebaseStore.setUser(user);

		}).catch(function (error) {
			console.log(error);
			that.setState({
				error,
			});

			try {
				reportErrorCode(error.code, error.message)
			} catch(e) {

			}
		  // User couldn't sign in (bad verification code?)
		  // ...
		});
	}

	_renderInputAndButtons() {
		if (this.state.error) {
			return <h5>An Error Occured... Please Try Again Later</h5>
		};

		if (!this.state.smsSent) {
			return (
				<div className="input-wrap">
					<div className="input-wrap__inner">
						<CountryCodeInput/>
						<input key="phonenum" ref="phone-input" className="phone-input" type="text" placeholder="Enter Phone #"/>
					</div>
					<TouchButtonHOC>
						<div id='sign-in-button' onClick={this.signInWithPhoneNumber.bind(this)} className="sign-in-button">Send Verification Code</div>
					</TouchButtonHOC>
				</div>
			)
		} else {
			return (
				<div className="input-wrap">
					<div className="input-wrap__inner">
						<input key="verification" ref="verification-code" className="phone-input" type="text" placeholder="Enter Verification Code"/>
					</div>
					<TouchButtonHOC>
						<div id='sign-in-button' onClick={this.verify.bind(this)} className="sign-in-button">Verify Phone</div>
					</TouchButtonHOC>
				</div>
			)
		}
	}

	render() {

		const inputAndButton = this._renderInputAndButtons();

		return (
			<div className="animate-in">
				<LoginText/>
				<span id="captcha-container"></span>
				{inputAndButton}
				<LoginFooter/>
			</div>
		)
	}
}

const LoginText = (props) => {
	return (
		<div>
			<h2 className="heading welcome">Welcome</h2>

			<p className="welcome-text">
				School University is a free online degree program that operates through Instagram.  Students will receive 8 lessons per day with 8 corresponding tests.  Top students are eligible to win $100 cash each day.  These scholarships are paid at the end of the day through Venmo.
			</p>
		</div>
	)
}

const LoginFooter = (props) => {
	return (
		<div className="login-footer">
			<img src="/img/school-university-logo.svg" alt=""/>
		</div>
	)
}

export default Login