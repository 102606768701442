import React from 'react'
import {observer} from 'mobx-react'
import {
	useStore
} from '../../hooks/useStore'

import get from 'lodash/get'

const DayAndSubjectLabel = observer((props) => {

	const {currentSubject, showBonusQuestion, day, contentfulStore: {currentDayContent}, firebaseStore: {userDayAnswers}, uiStore: {styles: {colors}}} = useStore();

	const subject = showBonusQuestion ? "Bonus" : get(currentDayContent, `fields.questions[${currentSubject - 1}].fields.subject`, '--');
	const showingGeneralScreen = !!userDayAnswers[currentSubject];

	const colorStyles = subject !== "--" ? 
		{
			background: colors[subject],
			color: "White"
		} :
		{};	 

	return (
		<div className={`day-and-subject-label ${subject} ${showingGeneralScreen && !showBonusQuestion ? "day-and-subject-label--margin-bottom" : ""}`}>
			<div>
				Day:0{day}
			</div>

			<div>
				<LessonLabel/>
			</div>

			<div style={colorStyles}>
				{showBonusQuestion ? "--" : subject.substr(0,3)}
			</div>

		</div>
	)
})

const LessonLabel = observer((props) => {
	const {currentSubject, showBonusQuestion} = useStore();

	return (
		showBonusQuestion ? <span>Bonus</span> :  <span>Lesson:{currentSubject === "--" ? currentSubject : `0${currentSubject}`}
		</span>)
})


export default DayAndSubjectLabel