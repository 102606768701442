import React from 'react'
import {observer} from 'mobx-react'
import {useStore} from '../../hooks/useStore'

import {
	CountdownClock
} from './'

const GeneralTimeRemainingClock = observer((props) => {
	const {currentHour} = useStore();

	return (
		<div className="general-time-remaining-clock">
			<span> {props.text}:&nbsp;</span> <CountdownClock hour={currentHour + 1}/>
		</div>
	)
})

export default GeneralTimeRemainingClock