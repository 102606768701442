import { action, computed, observable } from 'mobx';

const client = require('contentful').createClient({
	space: '2blqqeyhqpha',
	accessToken: 'wXrBf93DchdP6Q128nudLOOwXsDB4-qWTOhZ2-kLzD0'
});

class ContentfulStore {

	@observable currentDayContent = null;

	fetchCurrentDay(day) {
		const options = {
			content_type: 'day',
			'fields.day': day,
			include: 5
		};

		client.getEntries(options)
			.then(this.setCurrentDay)
	}

	@action.bound setCurrentDay(resp) {
		if (!resp.items) return

		this.currentDayContent = resp.items[0];
	}
}

export default ContentfulStore