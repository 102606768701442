import React from 'react'
import {observer} from 'mobx-react'

import {
	useStore
} from '../../hooks/useStore'

import get from 'lodash/get'

const Nav = (props) => {
	return (
		<div className="nav">
			<img className="logo" src="/img/school-university-logo.svg" alt=""/>
			<ColorLabel/>
		</div>
	)
}

const ColorLabel = observer((props) => {
	const {currentSubject, day, contentfulStore: {currentDayContent}, firebaseStore: {userDayAnswers}, uiStore: {styles: {colors}}} = useStore();

	const subject = get(currentDayContent, `fields.questions[${currentSubject - 1}].fields.subject`, '--');
	const showingGeneralScreen = !!userDayAnswers[currentSubject];


	return (<span className={`insta-scan ${subject}`}>InstaSCAN™</span>)
})

export default Nav