import React from 'react'
import {observer} from 'mobx-react'
import {
	useStore
} from '../hooks/useStore.js'

import {
	GeneralTimeRemainingClock,
	BonusLoadingSheet
} from './subcomponents'

import get from 'lodash/get'


const BonusQuestionSheet = (props) => {
	return (
		<div className="question-sheet page">
			<BonusLoadingSheet/>

			<Question/>
			<Image/>
			<Input />


			<BonusSubmitButton/>
			<GeneralTimeRemainingClock text={"Time Remaining"}/>

		</div>
	)
}	


const Question = observer((props) => {
	const {contentfulStore: {currentDayContent}} = useStore();
	const question = get(currentDayContent, "fields.bonusQuestion.fields.question","");

	return (
		<h2 className="question">{question}</h2>
	)
})

const Image = observer((props) => {
	const {contentfulStore: {currentDayContent}} = useStore();
	const bonusImage = get(currentDayContent, "fields.bonusQuestion.fields.image.fields.file.url","");

	return (
		<h2 className="bonus-image-section">
			<img className="bonus-image" src={`${bonusImage}?fm=jpg&q=90&w=600`} alt=""/>
		</h2>
	)
})

const Input = (props) => {
	const {uiStore: {updateBonusAnswer}} = useStore();

	return (
		<div className="bonus-input-section">
			<input onChange={updateBonusAnswer} type="text" placeholder="Enter number here"/>
		</div>
	)
}


const BonusSubmitButton = observer(() => {
		const {uiStore: {bonusAnswer, submittingAnswer}, firebaseStore: {updateAnswer}} = useStore();

		return (
			<div 
				className={`submit-button bonus-submit ${bonusAnswer && "submit-button--ready"}`}
				onClick={() => { updateAnswer("Bonus", bonusAnswer) }}>
				{submittingAnswer ? <Submitting/> : <h3>Submit</h3>}
			</div>
		)
})

const Submitting = (props) => {
	return (
		<h3 className="submitting">Submitting <span>.</span><span>.</span><span>.</span></h3>
	) 
}


export default BonusQuestionSheet