import React, {useEffect} from 'react'
import {observer} from 'mobx-react'

import {
	useStore
} from '../../hooks/useStore'

import {
	countryCodes
} from '../../helpers'

const CountryCodeInput = observer((props) => {
	const {uiStore: {setCountryCode, selectedCountryCode}} = useStore();

	const codes = countryCodes.codes;



	return (
		<div className="country-code-input">
			<span className="selected-country-code">{selectedCountryCode}</span>
			<select className="select" name="dialCode" onChange={(e) => setCountryCode(e)}>
				{codes.map((item, idx) => {
					return (
						<option key={`op-${idx}`} value={item.code} selected={item.code === "+1" && true}>{item.name} ({item.code})</option>
					)
				})
				}
			</select>
		</div>

	)
})


export default CountryCodeInput