import React from 'react'

import {
	reportDocumentRead
} from '../helpers'

const ArchivePage = (props) => {
	return (
		<div className="archive page animate-in">
			<img className="logo" src="/img/school-university-logo.svg" alt=""/>

			<h3 className="archive-heading">February 9th 2020 <br/> - <br/> February 14th 2020</h3>
			<div className="archive-body">
				<p>
					School University took place during a 1-week long residency at Copier Coller Club.  
					Each hour we posted video lessons on Instagram and participants would have the remainder of that hour to answer the questions on this web app.
					At the end of the day the top student was awarded $100 USD.
				</p>

				
				<div className="archive-links">
					<img src="/img/SOP-icon.png" alt=""/>
					<div>
						<h3>Statement of Purpose</h3>
						<div className="links">
							
							<div>
								<a href="https://docs.google.com/document/d/18h9x24xq_aoqPbd2mPLfWHfwQ0uhN3Brq1wSXYP9REc/edit?usp=sharing" onClick={() => reportDocumentRead("google_doc")}>Google Doc</a>
								<a href="/docs/[School University] Statement of Purpose.pdf" download onClick={() => reportDocumentRead("pdf")}>PDF</a>								
							</div>

						</div>
					</div>
				</div>
				{/*

<div className="archive-links">
	<a href="">
		<h3>An encapsulation of the project and some further reading materials</h3>
	</a>

	<a href="https://www.itsnicethat.com/news/school-university-instagram-course-digital-110220" target="_blank">
		<h3>A write-up from It's Nice That</h3>
	</a>
</div>
				*/}

			</div>

		</div>
	)
}

export default ArchivePage