import React, {useEffect} from 'react'
import {observer} from 'mobx-react'
import {useStore} from '../hooks/useStore'

const LoadingScreen = (props) => {
	const {currentHour,firebaseStore, removeLoadingScreen, hideLoadingLogo} = useStore();

	useEffect(() => {
		setTimeout(hideLoadingLogo, 1100);
		setTimeout(removeLoadingScreen, 1340);
	});

	return (
		<div className="loading-screen">
			<LoadingLogo/>

			<h5 className="offscreen-preload">a</h5>
			<h5 className="offscreen-preload alt">a</h5>
		</div>
	)
}


const LoadingLogo = observer(() => {
	const {isloadingLogoVisible} = useStore();

	return <img className={`loading-logo ${!isloadingLogoVisible && "loading-logo--hidden"}`} src="/img/school-university-logo.svg" alt=""/>
})

export default LoadingScreen