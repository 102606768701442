import React from 'react'
import {observer} from 'mobx-react'

import {
	useStore
} from '../../hooks/useStore'

const PhoneIDLabel = observer((props) => {
	const {firebaseStore: {user}} = useStore();
	return <div className="phone-id"><h4 >{user.phoneNumber}</h4></div>
})

export default PhoneIDLabel