import React, {useEffect} from 'react';
import {observer} from 'mobx-react';

import {useStore} from './hooks/useStore'

// import './App.css';
import './archive.scss'

import {
  // UserView,
  // Login,
  LoadingScreen,
  // DesktopScreen,
  ArchivePage
} from './pages'


const App = observer((props) =>  {
	const {firebaseStore, showLoadingScreen, isMobile} = useStore();

	// useEffect(() => {
	// 	firebaseStore.firebase.auth().onAuthStateChanged(function(user) {
	// 	    if (user) {
	// 				firebaseStore.setUser(user);
	// 			}
	// 	});
	// })

	if (showLoadingScreen) return <LoadingScreen/>

	return <ArchivePage/>
	

	// if (!isMobile) return <DesktopScreen/>

 //    return firebaseStore.user ? <UserView/> : <Login/>	
})

export default App;
