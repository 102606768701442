import React, {useState, useEffect} from 'react'
import {observer} from 'mobx-react'
import {useStore} from '../hooks/useStore.js'

import {
	TouchButtonHOC
} from './global'

import get from 'lodash/get';

const ReportCard = observer((props) => {
	return (
		<div className="report-card page">
			<h2 className="report-card-heading heading">Report Card</h2>
			<ReportCardTable/>

			<h5 className="report-card-msg">Classes start at 10AM EST</h5>
		</div>
	)
});


const ReportCardTable = (props) => {
	return (
		<div className="report-card-table">
			<ReportCardDaySection day={1}/>
			<ReportCardDaySection day={2}/>
			<ReportCardDaySection day={3}/>
			<ReportCardDaySection day={4}/>
			<ReportCardDaySection day={5}/>
		</div>
	)
};

const ReportCardDaySection = (props) => {
	const {currentHour, day, firebaseStore: {fetchUserScore}} = useStore();
	const [isOpen, toggleOpen] = useState(false);

	useEffect(() => {
		if (day >= props.day && currentHour > 17) {
			fetchUserScore(day);
		}
	})

	return (
		<div className="report-card-day-section">
			<TouchButtonHOC>
				<div className="report-card-day-head" onClick={() => toggleOpen(!isOpen)}>
					<h5>Day 0{props.day}</h5>
					<ScoreAndRank {...props}/>
				</div>
			</TouchButtonHOC>


			{isOpen ? <ReportCardDayContents {...props}/> : null}
		</div>
	)
}

const ScoreAndRank = observer((props) => {
	const {firebaseStore: {userScores}} = useStore();
	const scoreData = userScores[props.day];
	const score = get(scoreData, "numCorrect", "--")

	return (
		<div className="rank-and-grade"><h5>Score: {score}</h5></div>
	)
})


const ReportCardDayContents = observer((props) => {
	const {contentfulStore: {currentDayContent}, firebaseStore: {userScores}} = useStore();
	const scoreData = userScores[props.day];
	if (!scoreData) return null;

	const subjects = get(currentDayContent, "fields.questions", []);

	return (
		<div className="day-score-table">
			{subjects.map((data, idx) => {
				return <ReportCardDayRow key={`rp-d-r${props.day}-${idx}`}data={data} idx={idx} {...props}/>
			})}
		</div>
	)
})

const ReportCardDayRow = observer((props) => {
	const {firebaseStore: {userScores},uiStore: {styles: {colors}}} = useStore();
	const {data, idx} = props;

	const scoreData = userScores[props.day];
	if (!scoreData) return null;

	const colorStyles = {
		background: colors[data.fields.subject],
		color: "white",
		borderRight: "1px solid black"
	}

	return (
		<div className="subject-row row">
			<div style={colorStyles}>{data.fields.subject.substr(0,3)}</div>
			<div>{`Q-0${idx + 1}`}</div>
			<div>{scoreData[idx + 1]}</div>
		</div>
	)
})


export default ReportCard