import React, {PureComponent, Fragment} from 'react'
import {inject} from 'mobx-react'

@inject("store")
class TouchButtonHOC extends PureComponent {

    constructor(props) {
        super(props);
		this.state = {
			touched: false,
            color: ""
		}

        this.modifyChildren = this.modifyChildren.bind(this);
    }

    modifyChildren(child) {
        // const className = classNames(
        //     child.props.className,
        //     {...otherClassses}
        // );
        const className = `${child.props.className || ""} ${this.state.touched ? "touchable touchable--touched" : "touchable"}`
		const style = this.state.touched ? {background: this.state.color} : {}
        const onTouchStart = this._startTouch; 
		const onTouchEnd= this._endTouch; 

        const props = {
            className,
            onTouchStart,
            onTouchEnd,
            style
        };

        return React.cloneElement(child, props);
    }

    _startTouch = (e) => {
        const allColors = this.props.store.uiStore.styles.colors.paleColorsCropped;
        const color = allColors[Math.floor(allColors.length * Math.random())]
		this.setState({
			touched: true,
            color
		})
    }

    _endTouch = (e) => {

		this.setState({
			touched: false
		})
    }

    render() {
        return (
        	<Fragment>
	            {React.Children.map(this.props.children, child => this.modifyChildren(child))}
	        </Fragment>
	        );
    }

}

export default TouchButtonHOC