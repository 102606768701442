import React from 'react'
import {observer} from 'mobx-react'

import {
	useStore
} from '../../hooks/useStore'

const SubmitButton = observer((props) => {
	const {currentSubject, uiStore: {styles, selectedAnswer, submittingAnswer}, firebaseStore: {updateAnswer}} = useStore();

	const style = selectedAnswer ? {
		background: styles.colors.byIndex[currentSubject - 1]
	} : 
	{};

	return (
		<div 
			style={style}
			className={`submit-button ${selectedAnswer && "submit-button--ready"}`}
			onClick={() => updateAnswer(currentSubject, selectedAnswer)}>
			{submittingAnswer ? <Submitting/> : <h3>Submit</h3>}
		</div>
	)
})

const Submitting = (props) => {
	return (
		<h3 className="submitting">Submitting <span>.</span><span>.</span><span>.</span></h3>
	) 
}

export default SubmitButton