import React from 'react'
import {observer} from 'mobx-react'
import {useStore} from '../hooks/useStore.js'

import {
	CountdownClock,
	GeneralTimeRemainingClock,
	SubmitButton
} from './subcomponents'


const QuestionSheet = observer((props) => {
	return (
		<div className="question-sheet page">
			<Question/>
			<AnswersTable/>
			<AnswerButtons/>
			<SubmitButton/>

			<GeneralTimeRemainingClock text={"Time Remaining"}/>
		</div>
	)
})

const Question = observer((props) => {
	const {contentfulStore: {currentDayContent}, currentSubject} = useStore();
	const current = currentDayContent.fields.questions[currentSubject - 1];
	const question = current.fields.question;
	const subject = current.fields.subject;

	return (
		<h2 className={`question ${subject}`}>{question}</h2>
	)
})	

const AnswersTable = observer((props) => {
	const {contentfulStore: {currentDayContent}, currentSubject} = useStore();

	const current = currentDayContent.fields.questions[currentSubject - 1];

	const subject = current.fields.subject;
	const answers = current.fields.answersObj

	return (
		<div className={`answers-table table--${subject}`}>
			{Object.keys(answers).map((key, idx) => {
				return (
					<div key={`ant-${idx}`} className="answer-row row">
						<div>{key}</div>
						<div>{answers[key]}</div>
					</div>
				) 
			})}

		</div>
	)
})

const AnswerButtons = observer((props) => {

	const {currentSubject, uiStore: {styles, selectAnswer, selectedAnswer}} = useStore();

	const buttons =  ["A", "B", "C", "D"].map((letter, idx) => {
		const uiColor = styles.colors.byIndex[currentSubject - 1]
		const style = selectedAnswer === letter ? {
			background: uiColor,
			color: "#fff",
			borderColor: uiColor
		} : 
		{
			color: uiColor,
			borderColor: uiColor
		};

		return <div 
					key={`ans-bt-${idx}`} 
					style={style}
					className={`${selectedAnswer === letter && "answer-button--selected"} answer-button`} 
					onClick={selectAnswer.bind(null, letter)}>{letter}</div>
	});


	return (
		<div className="answer-buttons">
			{buttons}
		</div>
	)
})



export default QuestionSheet