import React from 'react'
import {observer} from 'mobx-react'
import {useStore} from '../../hooks/useStore'
import {getTimeUntil} from '../../helpers'

const CountdownClock = observer((props) => {
	const {time} = useStore();

	const sample = new Date(2020, time.getMonth(), time.getDate(), props.hour)

	const timeUntil = getTimeUntil(time, sample);

	return (
		<h6>
			{timeUntil}
		</h6>
	)
})

export default CountdownClock