import React, {useEffect} from 'react'
import {observer} from 'mobx-react'

import {useStore} from '../../hooks/useStore'

const BonusLoadingSheet = observer((props) => {
	const {uiStore: {fadeBonusLoadSheet, showBonusLoadSheet, startFadeBonusLoadSheet, removeBonusLoadSheet}} = useStore();

	useEffect(() => {
		// var element = document.getElementById("body");
		// element.classList.add("black");

		setTimeout(startFadeBonusLoadSheet, 1200)
		setTimeout(removeBonusLoadSheet, 1400)
	});

	if (!showBonusLoadSheet) return null;

	return (
		<div className={`bonus-loading-sheet ${fadeBonusLoadSheet && "bonus-loading-sheet--fade"}`}>
			<h1 className="heading">Bonus Round</h1>
		</div>
	)
})

export default BonusLoadingSheet