import { action, computed, observable } from 'mobx';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const config = {
  apiKey: "AIzaSyAMGMWBQn5hmBybElBHcPJxRjsERVn43Es",
  authDomain: "school-university.firebaseapp.com",
  databaseURL: "https://school-university.firebaseio.com",
  projectId: "school-university",
  storageBucket: "school-university.appspot.com",
  messagingSenderId: "37256007402",
  appId: "1:37256007402:web:eb4daa85140b1918801193",
  measurementId: "G-W279XB7RRE"
};

firebase.initializeApp(config) 


class FirebaseStore {
	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	database = firebase.firestore();
	firebase = firebase;

	@observable userDayAnswers = {};
	@observable userScores = {};
	@observable initialDataCheck = false;
	@observable user = null;


	@action.bound getUserDayAnswers() {
		this.database.collection(`day_${this.rootStore.day}`)
			.doc(this.user.phoneNumber)
			.get()
			.then(doc => {
				const data = doc.data();
				if (data) {
					this.userDayAnswers = data;
				}

				this.initialDataCheck = true;
			});
	}

	@action.bound updateAnswer(subject, answer) {
		console.log("updating answer")
		this.rootStore.uiStore.setSubmittingAnswer();
		const minTime = 1200;
		var t0 = performance.now();

		const uploadData = {};
		uploadData[subject] = answer;

		this.database.collection(`day_${this.rootStore.day}`)
			.doc(this.user.phoneNumber)
			.set(uploadData, {merge: true})
			.then(resp => {
				var t1 = performance.now();
				const timeElapsed = t1 - t0;
				const timeoutTime = timeElapsed >= minTime ? 0 : minTime - timeElapsed;

				setTimeout(resolveSubmission, timeoutTime)

			})

		const resolveSubmission = () => {
			this.rootStore.uiStore.removeSubmittingAnswer()
			this.userDayAnswers[subject] = answer;
		}
	}

	setupCaptcha() {
		window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('captcha-container', {
		  size: "invisible",
		  callback: function(response) {
		    // reCAPTCHA solved, allow signInWithPhoneNumber.
		    // this.onSignInSubmit();
		  }
		});
	}

	@action.bound setUser(user) {
		this.user = user;
		this.getUserDayAnswers();
		for (var i = 1; i < 6; i++) {

			if (i < this.rootStore.day) {
				this.fetchUserScore(i)
			} else if (this.rootStore.day === i && this.rootStore.currentHour > 17) {
				this.fetchUserScore(i)
			}
		}
	}


	@action.bound async fetchUserScore(day) {

		const userGrades = await this.database.collection(`grades_${day}`)
							.doc(this.user.phoneNumber)
							.get();
		
		
		const data = userGrades.data();

		this.userScores[day] = data;

	}
}

export default FirebaseStore