import React from 'react'
import {observer} from 'mobx-react'
import {useStore} from '../hooks/useStore.js'

import {
	CountdownClock,
	GeneralTimeRemainingClock
} from './subcomponents'

import get from 'lodash/get'

const SubjectsSheet = observer((props) => {
	const {currentHour, contentfulStore: {currentDayContent}} = useStore();

	return (
		<div className="subjects-sheet page">
			<SubjectsTable/>
			<GeneralTimeRemainingClock text={currentHour === 17 ? "Report Card" : "Next Question"}/>
		</div>
	)
})

const SubjectsTable = (props) => {
	const {contentfulStore: {currentDayContent}} = useStore();

	const subjects = get(currentDayContent, "fields.questions", []);

	return (
		<div className="subjects-table">
			{subjects.map((subject, idx) => {
				return <SubjectRow key={`sr-${idx}`} data={subject} idx={idx}/>
			})}
		</div>
	)
}

const SubjectRow = (props) => {
	const {data, idx} = props;
	const {uiStore: {styles: {colors}}} = useStore()

	const colorStyles = {
		background: colors[data.fields.subject],
		color: "white",
		borderRight: "1px solid black"
	}

	return (
		<div className="subject-row row">
			<div style={colorStyles}>{data.fields.subject.substr(0,3)}</div>
			<div>{`Q-0${idx + 1}`}</div>
			<div><CompletedOrClockLabel idx={idx}/></div>
		</div>
	)
}

const CompletedOrClockLabel = observer((props) => {
	const {currentHour, currentSubject,  firebaseStore: {userDayAnswers}} = useStore();
	const wasAnswered = !!userDayAnswers[props.idx + 1]

	return currentHour + 1 > 10 + props.idx ? <h3 className={`completion-label ${wasAnswered && "completion-label--answered"}`}>{wasAnswered ? `Answered` : "Incomplete"}</h3> : <CountdownClock  hour={10 + props.idx}/>
})



export default SubjectsSheet