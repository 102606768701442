import { action, computed, observable } from 'mobx';

class UIStore {
	styles = {
		colors: {
			Music: '#ffae00',
			Art: '#0086c8',
			Science: '#00ae00',
			Geography: '#ff7bac',
			Vocabulary: '#662d91',
			History: '#ff0049',
			Film: '#000000',
			Math: '#9DA9B0',
			Bonus: 'red',
			byIndex: [
				'#ffae00',
				'#0086c8',
				'#00ae00',
				'#ff7bac',
				'#662d91',
				'#ff0049',
				'#000000',
				'#869096'
			],
			paleColorsCropped: [
				'#FFF0D3',
				'#D2E8F4',
				'#D3F0D4',
				'#FFE6EE',
				'#E3D9EA',
				'#FFD2DE',
				"#D2D2D2"
			],
			paleColors: [
				'#FFF0D3',
				'#D2E8F4',
				'#D3F0D4',
				'#FFE6EE',
				'#E3D9EA',
				'#FFD2DE',
				'#D2D2D2',
				'#F2F5F6'
			]
		}
	}



	@observable selectedAnswer = null;
	@observable selectedCountryCode = "+1"
	@observable submittingAnswer = false;
	@observable bonusAnswer = "";

	@observable showBonusLoadSheet = true;
	@observable fadeBonusLoadSheet = false;

	@action.bound selectAnswer(answer) {

		this.selectedAnswer = answer;
		
	}

	@action.bound setCountryCode(e) {
		const code = e.target.value;
		
		this.selectedCountryCode = code
	}

	@action.bound setSubmittingAnswer() {
		this.submittingAnswer = true;
	}

	@action.bound removeSubmittingAnswer() {
		this.submittingAnswer = false;
	}

	@action.bound updateBonusAnswer(e) {
		const value = e.target.value;

		this.bonusAnswer = value;
	}

	@action.bound startFadeBonusLoadSheet() {
		this.fadeBonusLoadSheet = true;
	}

	@action.bound removeBonusLoadSheet() {
		this.showBonusLoadSheet = false;
	}

}	



export default UIStore