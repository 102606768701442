import ContentfulStore from './ContentfulStore';
import FirebaseStore from './FirebaseStore';
import UIStore from './UIStore';
import {
  determineDay,
  getCurrentDate
} from '../helpers'

import {observable, action, computed} from 'mobx'

import get from 'lodash/get'

var MobileDetect = require('mobile-detect');


class RootStore {
  constructor() {
    var md = new MobileDetect(window.navigator.userAgent);
    this.isMobile = !!md.phone();

    this.contentfulStore = new ContentfulStore(this);
    this.firebaseStore = new FirebaseStore(this);
    this.uiStore = new UIStore(this);

    this.day = determineDay();

    this.contentfulStore.fetchCurrentDay(this.day)
    this.updateTime();
  }


  @computed get isReady() {
  	return !!this.contentfulStore.currentDayContent && !!this.firebaseStore.initialDataCheck;
  }

  @computed get isAdmin() {
    return get(this.firebaseStore, "user.phoneNumber") === "+19145251113"
  }
  @computed get currentHour() {

    if (this.isAdmin) {
      // return 1
      return 17
    }

  	return this.time.getHours();
  }

  @computed get currentSubject() {
  	if (this.currentHour < 10 || this.currentHour > 17) return "--"

  	return this.currentHour - 9;
  }

  @computed get showBonusQuestion() {
    if (this.currentHour === 17 && this.firebaseStore.userDayAnswers['8'] && !this.firebaseStore.userDayAnswers["Bonus"]) {
      return true
    }

    return false
  }


  @observable time = null
  @observable ready = false;
  @observable showLoadingScreen = true;
  @observable isloadingLogoVisible= true;

  @action.bound updateTime() {
  	this.time = getCurrentDate();

  	setTimeout(this.updateTime, 500)
  }

  @action.bound removeLoadingScreen() {
    this.showLoadingScreen = false;
  }

  @action.bound hideLoadingLogo() {
    this.isloadingLogoVisible = false;
  }


}

const rootStore = new RootStore();
export default rootStore;

